<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="userListGrid"
          ref="userListGridRef"
          :data-source="dataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @exporting="onExporting"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGrid base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-search-panel
              :highlight-search-text="true"
              :search-visible-columns-only="true"
              :visible="true"
              :width="300"
              placeholder="Search..."
            />
            <dx-selection select-all-mode="allPages" show-check-boxes-mode="always" :width="10" mode="multiple" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
          <!--endregion ::DataGrid base components -->
          <!--region    ::DataGrid columns -->
          <dx-column data-field="fullName" />
          <dx-column data-field="email" />
          <dx-column data-field="login" caption="Username" />
          <dx-column data-field="registerTime" data-type="date" />
          <dx-column data-field="activated" caption="Verified" cell-template="activatedCellTemplate" />
          <template #activatedCellTemplate="{data}">
            <div>
              <span class="badge" :class="{ 'badge-success': data.value, 'badge-danger': !data.value }">
                {{ data.value ? 'Yes' : 'No' }}
              </span>
            </div>
          </template>
          <dx-column data-field="profileStatus" caption="Status" :customize-text="profileStatusCustomizeText" cell-template="profileStatusCellTemplate" />
          <template #profileStatusCellTemplate="{data}">
            <div>
              <span class="badge" :class="`badge-${profileStatusStyle(data.value)}`">{{ data.text }}</span>
            </div>
          </template>
          <!--endregion ::DataGrid columns -->
          <!--region    ::DataGrid actions -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
          <!-- TODO: sendReminderEmail function should be implemented -->
            <div>
              <dx-util-button
                type="default" icon="bi bi-reply-fill"
                hint="Send Reminder Email"
                @click="sendReminderEmail(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGrid actions -->
          <!--region    ::DataGrid toolbar -->
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-1">
                <dx-util-select-box
                  id="statusSelectBox"
                  v-model="selectedStatus"
                  :data-source="statusOptions"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="reload"
                />
              </div>
              <div class="mr-1">
                <dx-util-date-box
                  id="beginDateDateBox"
                  v-model="beginDate"
                  placeholder="Begin Date"
                  :max="endDate"
                  type="date"
                  date-serialization-format="yyyy-MM-dd"
                  @value-changed="reload"
                />
              </div>
              <div>
                <dx-util-date-box
                  id="endDateDateBox"
                  v-model="endDate"
                  placeholder="End Date"
                  :min="beginDate"
                  type="date"
                  date-serialization-format="yyyy-MM-dd"
                  @value-changed="reload"
                />
              </div>
            </div>
          </template>
          <!--endregion    ::DataGrid toolbar -->
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import { userListDataSource } from './userListStore'

export default {
  mixins: [GridBase],
  data() {
    return {
      dataSource: userListDataSource,
      query: '',
      beginDate: null,
      endDate: null,
      selectedStatus: 'ALL',
      statusOptions: [
        { text: 'Status (All)', value: 'ALL' },
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Pending', value: 'PENDING' },
        { text: 'Profile Missing', value: 'EMAIL_VALIDATED_PROFILE_MISSING' },
        { text: 'Deleted', value: 'DELETED' },
      ],
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.userListGridRef.instance
      return grid
    },
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.initialLoad()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.reload()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.reload()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.reload()
          }
        }
      }
    },
    profileStatusCustomizeText(e) {
      let valueText = ''
      switch (e.value) {
        case 'ACTIVE':
          valueText = 'Active'
          break
        case 'PENDING':
          valueText = 'Pending'
          break
        case 'EMAIL_VALIDATED_PROFILE_MISSING':
          valueText = 'Profile Missing'
          break
        case 'DELETED':
          valueText = 'Deleted'
          break
        default:
          valueText = ''
          break
      }
      return valueText
    },
    profileStatusStyle(status) {
      if (status === 'ACTIVE') return 'success'
      if (status === 'PENDING') return 'warning'
      if (status === 'EMAIL_VALIDATED_PROFILE_MISSING') return 'danger'
      return 'primary'
    },
    initialLoad() {
      UserSearchFilter.setDefaultFilters()
      const filters = UserSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.beginDate = this.beginDate
      UserSearchFilter.endDate = this.endDate
      UserSearchFilter.status = this.selectedStatus
      UserSearchFilter.q = this.query
      const filters = UserSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    sendReminderEmail() {
      // TODO: to be implemented
    },
  },
}
</script>

<style>

</style>
