import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import userManagementService from '@/http/requests/system/userManagementService'
import Pager from '@core/dev-extreme/utils/pager'

function sortFieldMap(sort) {
  const sortBy = []
  sort.forEach(c => {
    let field = c.selector
    if (c.selector === 'registerTime') field = 'created_date'
    if (c.selector === 'profileStatus') field = 'user_profile_status'
    if (c.selector === 'fullName') field = 'first_name'
    sortBy.push({ selector: field, desc: c.desc })
  })
  return sortBy
}

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? sortFieldMap(loadOptions.sort) : [{ selector: 'id', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null

    return userManagementService
      .getUserInfoBasicByQuery(filters, pageableQuery)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const userListDataSource = new DataSource({
  store: store,
})

export {
  userListDataSource,
}
